<template>
  <div class="out" :class="$i18n.locale == 'en' ? 'en-style' : ''" ref="out">
    <van-pull-refresh v-model="isLoading" @refresh="loadDoodStateLoad">
      <div class="header">
        <div class="logo Title">

          <div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
              @click="$router.back()" />{{ $t('main.tM') }}</div>
        </div>
        <div class="right-icon">
          <img src="../assets/img/logo-icon.png" alt="" />
        </div>
      </div>
      <div class="content">
        <div class="zb-gl">
          <div class="tap-box">
            <div class="tap-item success_state" @click="currIndex(1)">
              <div :class="[curr_index == 1 ? 'active' : '']">
                <div>
                  {{ $t('main.approved') }}({{
                    goodsState.total_pass ? goodsState.total_pass : 0
                  }})
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="tap-item in_review_state" @click="currIndex(0)">
              <div :class="[curr_index == 0 ? 'active' : '']">
                <div>
                  {{ $t('main.ur') }}({{
                    goodsState.total_wait ? goodsState.total_wait : 0
                  }})
                </div>
              </div>
            </div>

            <div class="line"></div>
            <div class="tap-item refuse_state" @click="currIndex(2)">
              <div :class="[curr_index == 2 ? 'active' : '']">
                <div>
                  {{ $t('main.fail') }}({{
                    goodsState.total_fail ? goodsState.total_fail : 0
                  }})
                </div>
              </div>
            </div>
          </div>
          <div class="zb-gl-box">
            <div class="cishu">
              <img src="../assets/img/cishu.png" alt="" />
              <div>{{ $t('main.rempoint') }}：{{ verify_times }}</div>
              <div class="gotocz" @click="gotoCZ">{{ $t('main.recharge') }}</div>
            </div>
            <div class="zb-pic zb-gl-item" :class="[`zb_gl_item${item.status}`]" v-for="item in data_list" :key="item.id">
              <div class="check-box">
                <div v-show="item.status != 0" :style="item.status == 1 && item.is_show != 0 ? '' : 'visibility: hidden;'"
                  v-if="data_list.length > 3">
                  <input type="checkbox" :id="'tj' + item.id" :checked="item.is_recommend == 1 ? true : false"
                    @change="recommend($event, item.id)" />
                  <label :for="'tj' + item.id">{{ $t('main.recommend') }}</label>
                </div>
                <!-- <div class="check-box" v-show="item.status != 0"
                  :style="item.status == 1 && !item.collector && item.is_show != 0 ? '' : 'visibility: hidden;'">
                  <input type="checkbox" :id="'sm' + item.id" :checked="item.is_can_buy == 1 ? true : false"
                    @change="CanBuy($event, item.id)" />
                  <label :for="'sm' + item.id">{{ $t('main.publicS') }}</label>
                  <img class="question" v-show="help.want_to_buy" @click="showContent('want_to_buy')"
                    src="../assets/img/question.png" alt="" srcset="">
                </div> -->
                <div class="delete" @click="handleDele(item.id)" v-if="item.status != 0"
                  :style="item.collector || item.artist_source == 2 ? 'visibility: hidden;' : ''">
                  <img src="../assets/img/delete_r.png" alt="" srcset="" />
                  {{ $t('main.delete') }}
                </div>
              </div>
              <div class="zb-gl-item-top">
                <div class="zb-gl-item-top-left" @click="goDetail(item)">
                  <img v-lazy="(item.pictures[0] + '?width=200').replace(/((^[A-Za-z]{4,5})+\:)/, protocol)" alt="" />
                </div>
                <div class="zb-gl-item-top-right">
                  <div class="zb-title" @click="goDetail(item)">
                    {{ item.name }}
                  </div>
                  <div class="zb-code">
                    {{ $t('main.gwtc') }}{{
                      item.serial_number ? item.serial_number : "--------"
                    }}
                  </div>
                  <div class="zb-issue-date">
                    {{ $t('main.relT') }}{{ item.created_at }}
                  </div>
                  <div class="zb-value">
                    {{ $t('main.mv') }}：￥{{
                      item.collector ? item.collector.price : item.price
                    }}
                  </div>
                </div>
              </div>
              <div class="zb_renzhen" v-show="item.status == 1">
                <div class="zb-rz-adopt" v-show="item.certificate">
                  <img src="../assets/img/sucess_nor.png" alt="" />
                  <span>{{ $t('main.tpcc') }}</span>
                </div>
                <div class="zb-tips-item zb-rz-rare-adopt" v-if="item.is_rare == 1">
                  <div>
                    <img src="../assets/img/rare-icon.png" alt="" />
                  </div>
                  <span>{{ $t('main.ptrtc') }}</span>
                </div>
              </div>
              <div class="zb-gl-item-bottom" v-show="!(item.status == 1 && item.is_show == 0)">
                <div class="zb-tips-gn">
                  <div class="zb-tips-gn-item" v-if="item.status == 1" @click="signW(item)"
                    :style="isAcc ? 'display:none;' : ''">
                    <img src="../assets/img/qmlkkk.png" alt="" />
                    <div class="text">{{ $t('main.signoff') }}</div>
                    <img class="is_info" v-show="isSignP(item)" src="../assets/img/tips.png"
                      alt="" />
                  </div>
                  <!-- <div class="zb-tips-gn-item" v-if="item.status == 1 && !item.certificate" @click="newCe(item)"
                    :style="isAcc ? 'display:none;' : ''">
                    <img src="../assets/img/gl_zs.png" alt="" />
                    <div class="text">生成证书</div>
                  </div> -->
                  <div class="zb-tips-gn-item" v-if="item.status == 1" @click="actionS(item)">
                    <img src="../assets/img/gl_xz.png" alt="" />
                    <div class="text">{{ $t('main.dl') }}</div>
                  </div>

                  <div :class="[
                    item.status != 1 ? 'xg-btn' : '',
                    'zb-tips-gn-item',
                  ]" :style="item.collector || item.artist_source == 2 ? 'display:none;' : ''" @click="editData(item.id)">
                    <img src="../assets/img/gl_xg.png" alt="" />
                    <div class="text">{{ $t('main.modify') }}</div>
                  </div>
                  <div class="zb-tips-gn-item" v-if="item.status == 1" :style=" getCollect(item)
                      ? 'display:none;'
                      : ''
                    " @click="goZY(item)">
                    <img src="../assets/img/gl_cy.png" alt="" />
                    <div class="text" :style=" $i18n.locale == 'en'? '' :'width:0.4rem'">{{ $t('main.Hcertification ') }}</div>
                    <img class="is_info" v-show="item.apply_collect.length" src="../assets/img/tips.png" alt="" />
                  </div>
                  <!-- <div class="zb-tips-gn-item" v-if="item.status == 1" :style="item.collector ||
                      (item.status == 1 && item.my_edit.status == 0) || isAcc
                      ? 'display:none;'
                      : ''
                    " @click="goBI(item)">
                    <img src="../assets/img/gl_qg.png" alt="" />
                    <div class="text">{{ $t('main.purshRe') }}</div>
                    <img class="is_info" v-show="item.has_new_buy == 1" src="../assets/img/tips.png" alt="">
                  </div> -->
                  <div :class="[
                    item.status != 1 ? 'fz-btn' : '',
                    'zb-tips-gn-item',
                  ]" :style="(item.status == 1 && item.my_edit.status == 0 || item.status == 2)||item.artist_source == 2
    ? 'display:none;'
    : ''
  " @click="copyZB(item)">
                    <img src="../assets/img/fzzb.png" alt="" />
                    <div class="text">{{ $t('main.copyTr') }}</div>
                    <!-- <img class="is_info" v-show="item.apply_collect.length" src="../assets/img/tips.png" alt=""> -->
                  </div>
                  <div class="zb-tips-gn-item" v-if="item.status == 1" :style="item.collector ||
                      (item.status == 1 && item.my_edit.status == 0)||item.artist_source == 2
                      ? 'display:none;'
                      : ''
                    ">
                    <img src="../assets/img/gl_xj.png" alt="">
                    <div @click="adtiViola(item.id)">{{ $t('main.detailUpload') }}</div>
                  </div>
                  <div class="zb-tips-gn-item" @click="goLm(item)" v-if="item.status == 1" :style="item.collector ||
(item.status == 1 && item.my_edit.status == 0)||item.artist_source == 2
                      ? 'display:none;'
                      : '' ">
                    <img src="../assets/img/unit_logo.png" alt="">
                    <div>珍宝联名</div>
                  </div>
                  <div style="width:0;height:0.48rem"></div>
                </div>
                <div class="aiyy-logo-wrap" v-show="item.status == 1">
                  <img class="aiyy-logo" :style="item.audio_type == 1 ? 'filter: grayscale(1);' : ''"
                    v-if="item.audio_type && item.audio_type != 0" src="../assets/img/aiyy-guanli-logo.png" alt="">
                  <span style="color:#333; line-height:0.16rem" v-if="item.audio_type == 1">{{ $t('main.aginpro') }}</span>
                  <span style="color:#1C129A;line-height:0.16rem" v-if="item.audio_type == 2">{{ $t('main.aalineff')
                  }}</span>

                  <div class="apply3D" v-if="item.is_dimensional== 2 || item.is_dimensional== 4" :style="item.is_dimensional == 2 ? '' : 'filter: grayscale(1);'">
                    <img src="../assets/img/3d_tips.png" alt="">
                    <span>
                      3D珍宝{{item.is_dimensional == 2?'已上传':'制作中'}}
                    </span>
                  </div>
                  <div class="link_ter" v-if="item.link_artist_id">
                    <img src="../assets/img/ui_logo.png" alt="">
                    联名珍宝
                  </div>
                </div>
              </div>
              <div class="is_hidden" v-show="item.status == 1 && item.is_show == 0">
                {{ $t('main.bban') }}
              </div>
              <div class="is_fail" v-if="item.status == 2">
                {{ item.fail_reason ? item.fail_reason : "" }}
              </div>
            </div>
          </div>

          <div class="password-box pop" v-if="qrcode" @click="closeQr">
            <div class="pop-in1" @click.stop>
              <div class="title">
                <img src="../assets/img/title-left-icon.png" alt="" />
                <span>{{ $t('main.pesignpwd') }}</span>
                <img src="../assets/img/title-right-icon.png" alt="" />
              </div>
              <div class="con">
                <div class="con-tips">
                  {{ $t('main.pcscc') }}
                </div>
                <div class="pass-input">
                  <div class="">
                    <label for="">{{ $t('main.password') }}</label><input v-model="pwd" @input="changerr"
                      :placeholder="$t('main.piypwdlest6')" type="password" />
                  </div>
                </div>
                <div class="err-tips" v-show="pwde">
                  <img src="../assets/img/err-tips-icon.png" alt="" />
                  {{ msg }}
                </div>
                <!-- <div class="agree-box-out">
						<div class="agree-box">
							@input="checkedFlag"
							<input  type="checkbox" value="" id="agree-select"
								:checked="goRegFlag" />
							<label id="" for="agree-select">{{$t('main.agreeC')}}</label>
						</div>
						<div class="agree-text">
							《文字文字文字》
						</div>
					</div> -->
              </div>
              <div class="confirm-btn" @click="postpwd($event)">{{ $t('main.determine') }}</div>
            </div>
          </div>

          <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
            <div class="pop-qrcode">
              <div>
                <img :src="nowZS" alt="" />
              </div>
              <div class="pop-tishi"><img src="../assets/img/click-logo-zm.png" alt=""> {{ $t('main.lodqr') }}</div>
            </div>
          </div>

          <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
            <div class="pop-qrcode">
              <div>
                <img :src="nowZS" alt="" />
              </div>
              <div class="pop-tishi"><img src="../assets/img/click-logo-zm.png" alt=""> {{ $t('main.lodqr') }}</div>
            </div>
          </div>

          <!--   -->
          <div class="pop pop-cy-rz" v-show="link_zy" >
              
              <div class="pop_zhuanyi" @click.stop>
                <img class="pop_close" @click="link_zy = false" src="../assets/img/close_white.png" alt="">
                <div class="pop_titile">

                  {{ $t('main.holdertran') }}

                </div>
                <div class="pop_avatar">
                  <img :src="pop.avatar" alt="" />
                </div>
                <div class="pop_wx_name">
                  {{ pop.username }}<span>{{ $t('main.syatca') }}</span>
                </div>
                <diV class="pop_main">
                  <div>{{ $t('main.certrinfo') }}</div>
                  <div>{{ $t('main.trname') }}：
                    <span>{{ pop.name }}</span>
                  </div>
                  <div>{{ $t('main.gwtc') }}
                    {{ pop.serial_number }}
                  </div>
                  <div>
                    {{ $t('main.cerdisplay') }}
                    {{  pop.username }}
                  </div>
                </diV>
                <div class="view_btn" @click="verify(1)" style="margin-bottom:0.1rem">{{$t('main.confirmTran')}}</div>
				        <div class="" @click="verify(2)">取消认证</div>
              </div>
          </div>




        </div>
      </div>
    </van-pull-refresh>
    <img src="../assets/img/guanli_qrcode.png" id="border" style="display:none" alt="">
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { ImagePreview, Dialog } from "vant";
export default {
  data() {
    return {
      curr_index: 1,
      data_list: [],
      pic1: "",
      pic2: "",
      pic3: "",
      goodsState: {
        total_pass: 0,
        total: 0,
      },
      deleteGoodFlags: false,
      cy_rz_flags: false,
      token: "",
      qrcode: false,
      qrcode1: false,
      nowZS: "",
      verify_times: 0,
      touching: false,
      isLoading: false,
      pwde: false,
      now_id: 0,
      pwd: "",
      msg: this.$t('main.wrongPw'),
      allsign: false,
      is_reset: false,
      has_sign: false,
      is_sign_pass: true,
      price_list: [],
      isAcc: false,
      protocol: 'http',
      is_next: false,
      loading1: false,
      page: 1,
      help: {},
      newdata:'',
      pop:{

      },
      link_zy:false
    };
  },

  async mounted() {
    this.$refs.out.addEventListener('scroll', this.throttle(this.scrollFn, 500))
    this.protocol = location.protocol
    this.isAcc = localStorage.getItem('isAcc') == 'true'
    if (this.$route.query.status) {
      this.curr_index = this.$route.query.status;
    }
    this.axios.get('/goods/level').then((res) => {
      this.price_list = res.data.data.list
    })
    
				await this.axios.post('common/encrypt',{id: localStorage.getItem("art_id")}).then((res)=>{
					this.newdata = res.data.data.data_encrypted
				})
    this.axios
      .post("/artist/detail", { data:this.newdata })
      .then((res) => {
        this.verify_times = res.data.data.score;
      });
    this.loadDoodState();
    this.axios.get("/artist/sign").then((res) => {
      if (res.data.data) {
        this.is_reset = !!(res.data.data.is_reset == 1);
        this.has_sign = !!res.data.data.sign;
        this.is_sign_pass = !(res.data.data.status == 2);
        this.allsign = !!(res.data.data.has_password == 1);
      } else {
        this.allsign = false;
      }
    });
    this.loadData(this.curr_index);

    this.pic1 = require("@/assets/img/adopt-icon.png");
    this.pic2 = require("@/assets/img/under-review-icon.png");
    this.pic3 = require("@/assets/img/fail-icon.png");
    if (this.$i18n.locale == 'en') {
      let data = JSON.parse(localStorage.getItem('helpinfo'))
      Object.keys(data).forEach((item) => {
        if (item.indexOf('_intl') != -1) {
          let label = item.split('_intl')[0]
          if (data[item]) {
            data[label] = data[item]
          }
        }
      })
      this.help = data
    } else {
      this.help = JSON.parse(localStorage.getItem('helpinfo'))
    }
  },
  methods: {
    showContent(key) {
      Dialog.alert({ message: this.help[key], confirmButtonColor: '#2d71d7' })
    },
    loadDoodState() {
      this.axios
        .get("/goods/mine/stat", {})
        .then((res) => {
          this.goodsState = res.data.data;
        })
        .catch((err) => { });
    },
    loadDoodStateLoad() {
      this.isLoading = true;
      Promise.all([
        this.axios.get("/goods/mine/stat", {}),
        this.axios.get("/goods/mine?status=" + this.curr_index, {})
      ]).then((res) => {
        let [res1, res2] = res;
        this.goodsState = res1.data.data;
        if (this.$i18n.locale == 'en') {
          for (let i = 0; i < res2.data.data.items.length; i++) {
            res2.data.data.items[i].name = res2.data.data.items[i].name_intl ? res2.data.data.items[i].name_intl : res2.data.data.items[i].name
          }
        }
        this.data_list = res2.data.data.items;
        this.isLoading = false
        this.is_next = res2.data.data.hasMorePages
      });
    },
    update() {
      Promise.all([
        this.axios.get("/goods/mine/stat", {}),
        this.axios.get("/goods/mine?status=" + this.curr_index, {}),
        this.axios.post("/artist/detail", { data:this.newdata })
      ]).then((res) => {
        let [res1, res2, res3] = res;
        this.goodsState = res1.data.data;
        if (this.$i18n.locale == 'en') {
          for (let i = 0; i < res2.data.data.items.length; i++) {
            res2.data.data.items[i].name = res2.data.data.items[i].name_intl ? res2.data.data.items[i].name_intl : res2.data.data.items[i].name
          }
        }
        this.data_list = res2.data.data.items;
        this.verify_times = res3.data.data.score;
        this.is_next = res2.data.data.hasMorePages
      });
    },
    loadData(id) {
      this.axios
        .get("/goods/mine?status=" + id, {})
        .then((res) => {
          if (this.$i18n.locale == 'en') {
            for (let i = 0; i < res.data.data.items.length; i++) {
              res.data.data.items[i].name = res.data.data.items[i].name_intl ? res.data.data.items[i].name_intl : res.data.data.items[i].name
            }
          }
          this.data_list = res.data.data.items;
          this.is_next = res.data.data.hasMorePages
        })
        .catch((err) => { });
    },
    editData(id) {
      this.$router.push("/renzhen?id=" + id + "&status=" + this.curr_index + '&time=' + new Date().getTime());
    },
    handleDele(id) {
      weui.confirm(this.$t('main.cdeletetr'), {
        title: this.$t('main.deleteSt'),
        buttons: [
          { label: this.$t('main.thinkTwice'), type: "primary", onClick: () => { } },
          {
            label: this.$t('main.delete'),
            type: "warn",
            className: "warn_dialog",
            onClick: () => {
              this.goodsDelete(id);
            },
          },
        ],
      });
    },
    goZY(item) {
      if(item.artist_source == 2){
        this.pop ={
          avatar:item.collector.avatar,
          username:item.collector.name,
          user_id:item.collector.user_id,
          name:item.name,
          serial_number:item.serial_number,
          id:item.apply_collect_link[0].id
        }
        this.link_zy = true
        return
      }
      this.$router.push({
        path: "/zhuanyi/" + item.id,
        query: { name: item.name, serial_number: item.serial_number },
      });
    },
    goBI(item) {
      this.$router.push({
        path: "/buy_info/" + item.id,
      });
    },
    // 删除珍宝
    goodsDelete(id) {
      this.deleteGoodFlags = true;
      let id_type = "goods_id";
      if (this.curr_index != 1) {
        id_type = "id";
      }
      this.axios.delete("/goods/delete?" + id_type + "=" + id).then((res) => {
        if (res.data.code == 0) {
          setTimeout(() => {
            weui.alert(this.$t('main.deleteTr'), { buttons: [{ label: this.$t('main.confIrm') }] });
          }, 500);
          this.update();
        }
      });
      // this.axios.post('/news/store', {
      // 	list:this.news_data,
      // 	token: this.token
      // }).then((res) => {
      // 	console.log(res.data)

      // }).catch((err) => {
      // 	console.log(err);
      // })
    },
    checkedFlag() { },
    currIndex(index) {
      this.curr_index = index;
      this.page = 1
      this.loadData(this.curr_index);
    },
    cancelBtn() {
      this.deleteGoodFlags = false;
    },
    hidePop() {
      this.deleteGoodFlags = false;
      // this.$router.push('/master');
    },
    recommend(e, id) {


      let statusPd = e.target.checked ? 1 : 0
      this.axios
        .post("/goods/recommend", {
          goods_id: id,
          switch: statusPd,
        })
        .then((res) => {
          if (res.data.code == -1) {
            console.log(weui.toast)
            weui.topTips(this.$t('main.recomm4'), 2000);
            // console.log(e.target.checked)
            e.target.checked = false
          } else {
            weui.topTips(this.$t('main.setSucc'), 1500);
          }
        });
    },
    CanBuy(e, id) {


      let statusPd = e.target.checked ? 1 : 0
      this.axios.post("/goods/setCanBuy", {
        goods_id: id,
        status: statusPd,
      }).then((res) => {
        weui.topTips(this.$t('main.setSucc'), 1500)
      })
    },
    actionS(item) {
      if(item.certificate){
        weui.actionSheet([
          {
            label: this.$t('main.downqr'),
            onClick: () => {
              this.DownLoadQRcode(item);
            },
          },
          {
            label: this.$t('main.downce'),
            onClick: () => {
              this.DownZS(item);
            },
          },
        ]);
      }else{
        weui.actionSheet([
          {
            label: this.$t('main.downqr'),
            onClick: () => {
              this.DownLoadQRcode(item);
            },
          },
        ]);
      }
      
    },
    gotoCZ() {
      this.$router.push("/chongzhi");
    },
    DownLoadQRcode(item) {
      let url = location.protocol + "//" + location.host;
      let div = document.createElement("div");
      let code = new QRCode(div, {
        text: url + "/detail/" + item.id, //要生成二维码的网址
        width: 410, //图像宽度
        height: 410, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        margin: 0, //外边距
        correctLevel: QRCode.CorrectLevel.L, //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
      });
      let canvas = code._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
      let qr_ctx = canvas.getContext('2d')
      let qr_img = qr_ctx.getImageData(0, 0, canvas.width, canvas.height)


      // ctx.font="50px Arial";
      // ctx.fillStyle = '#FFFFFF'
      // ctx.textAlign = "center"


      var canvas1 = document.createElement('canvas')
      var ctx = canvas1.getContext('2d')
      var border = document.getElementById('border')





      canvas1.width = border.width
      canvas1.height = border.height


      var qr_img_top = 160
      var nameHeight = 100
      var borderHeight = 640
      if (item.name.length > 13) {
        qr_img_top = 200
        nameHeight = 130
        borderHeight = canvas1.height
      }


      ctx.drawImage(border, 0, 0, canvas1.width, borderHeight)

      this.drawText(item.name, canvas1.width / 2, nameHeight, 380, ctx)
      // ctx.fillRect(30,100,430,430)
      ctx.fillStyle = '#FFFFFF'
      ctx.fillRect(30, qr_img_top - 10, 430, 430)
      ctx.putImageData(qr_img, 40, qr_img_top)
      this.nowZS = canvas1.toDataURL("image/png");

      this.qrcode1 = true;
    },
    DownZS(item) {
      ImagePreview({ images: [item.certificate], showIndex: false });
    },
    signW(item) {
      if (!this.is_sign_pass) {
        weui.alert(this.$t('main.ysnoremi'), {
          buttons: [{
            label: this.$t('main.confIrm'), onClick: () => {
              this.$router.push("/sign");
            }
          }]
        });
        return;
      } else if (!this.has_sign) {
        weui.alert(this.$t('main.nosetsse'), {
          buttons: [{
            label: this.$t('main.confIrm'), onClick: () => {
              this.$router.push("/sign");
            }
          }]
        });
        return;
      } else if (!this.allsign) {

        weui.alert(this.$t('main.nosetpwnse'), {
          buttons: [{
            label: this.$t('main.confIrm'), onClick: () => {
              this.$router.push("/sign");
            }
          }]
        });
        return;
      } else if (item.artist_source == 2){
        let list = item.link_artist_list
        let newItem = list.find((i)=>{return i.id == localStorage.getItem("art_id")}) || null
        if(newItem){
          if(newItem.is_sign == 0){
            this.pwde = false;
            this.pwd = "";
            this.now_id = item.id;
            this.qrcode = true;
            return
          }
        }else{
          this.pwde = false;
          this.pwd = "";
          this.now_id = item.id;
          this.qrcode = true;
          return
        }
        weui.alert(this.$t('main.trisSigned'), { buttons: [{ label: this.$t('main.confIrm') }] });
        return
      }
      else if (item.is_sign == 1) {
        weui.alert(this.$t('main.trisSigned'), { buttons: [{ label: this.$t('main.confIrm') }] });
        return;
      } else if (this.is_reset) {
        weui.alert(this.$t('main.pwrpwnc'), {
          buttons: [{
            label: this.$t('main.confIrm'), onClick: () => {
              this.$router.push("/sign");
            }
          }]
        });
        return;
      }
      this.pwde = false;
      this.pwd = "";
      this.now_id = item.id;
      this.qrcode = true;
    },
    // DownLoadZS(certificate){
    // 	ImagePreview({images:[certificate],showIndex:false,})
    // },
    closeQr() {
      this.qrcode = false;
    },
    closeQr1() {
      this.qrcode1 = false;
    },
    postpwd(e) {

      e.target.blur()
      if (!this.pwd) {
        this.msg = this.$t('main.pwcnbb');
        this.pwde = true;
        return;
      }
      if (this.pwd.length < 6) {
        this.msg = this.$t('main.pwnl6');
        this.pwde = true;
        return;
      }
      this.axios
        .post("/goods/sign", {
          goods_id: this.now_id,
          password: this.pwd,
          is_sign: 1,
        })
        .then((res) => {
          if (res.data.code != 0) {
            this.msg = res.data.msg;

            this.pwde = true;
            return;
          }
          weui.toast(this.$t('main.signsucc'), () => {
            Promise.all([
              this.axios.get("/goods/mine/stat", {}),
              this.axios.get("/goods/mine?status=" + this.curr_index, {}),
            ]).then((res) => {
              let [res1, res2] = res;
              this.goodsState = res1.data.data;
              this.data_list = res2.data.data.items;
              this.is_next = res2.data.data.hasMorePages
            });
            this.qrcode = false;
          });
        });
    },
    changerr() {
      this.pwde = false;
    },
    goDetail(item) {
      if (item.status == 2) {
        return;
      } else if (item.is_show == 0) {
        weui.alert(this.$t('main.trisbanpc'), { buttons: [{ label: this.$t('main.confIrm') }] });
        return;
      }
      let id = item.goods_id ? item.goods_id : item.id;
      this.$router.push(`/detail/${id}?status=1`);
    },
    copyZB(item) {
      //   let name = this.price_list.find((item1)=>{
      // return item1.id == item.level_id
      // })

      // if(name.name == '稀有珍宝'){
      //   weui.alert(`稀有珍宝不能复制`)
      //   return
      // }

      let param = {
        type: 2,
        name: item.name,
        info: item.info,
        pictures: item.pictures,
        manufacturing_date: item.manufacturing_date,
        description: item.description,
        honor: item.honor,
        price: item.price,
        level_id: item.level_id
      };
      if (item.detail_status != 2) {
        if(item.detail_info){
          param.detail_info = JSON.parse(item.detail_info)
        }
        param.detail_status = item.detail_status
      }


      let price = this.price_list.find((item1) => {
        return item1.id == item.level_id
      }).auth_fee

      weui.alert(`${this.$t('main.copyT')}《${item.name}》<br/> ${this.$t('main.ntpp',{N:price})} <br/>  ${this.$t('main.coptTips')} `, {
        buttons: [
          {
            label: this.$t('main.cancel'),
            onClick: () => { },
          },
          {
            label: this.$t('main.determine'),
            type: "primary",
            onClick: () => {
              this.postCopy(param);
            },
          },
        ],
      });
    },
    // 细节上传
    adtiViola(id) {
      this.$router.push(`/imgUpdate/${id}`)
    },
    postCopy(param) {
      this.axios.post("/goods/store", param).then((res) => {
        if (res.data.code == 0) {
          setTimeout(() => {
            this.axios.post('/goods/transGoodsInfo', { id: res.data.data.id })
            weui.alert(this.$t('main.copySucc'), {
              buttons: [{
                label: this.$t('main.confIrm'), onClick: () => {
                  this.update()
                }
              }]
            });
          }, 500);
        } else {
          if (res.data.msg.indexOf("积分") != -1) {
            setTimeout(() => {
              weui.alert(this.$t('main.pointsgocz'), {
                buttons: [
                  {
                    label: this.$t('main.cancel'),
                    onClick: () => { },
                  },
                  {
                    label: this.$t('main.determine'),
                    type: "primary",
                    onClick: () => {

                      this.$router.push("/chongzhi?status=1");

                    },
                  },
                ],
              });
            }, 500);
          }
        }
      });
    },
    throttle(fn, time) {
      let canRun = true;
      return function () {

        if (!canRun)
          return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this);
          canRun = true;
        }, time)
      }
    },
    scrollFn(e) {

      if (this.$refs.out.scrollHeight - this.$refs.out.scrollTop - this.$refs.out.clientHeight <= 20) {


        if (this.is_next && !this.loading1) {
          this.page++
          this.loading1 = true
          this.axios
            .get("/goods/mine?status=" + this.curr_index + '&page=' + this.page, {})
            .then((res) => {
              let list = res.data.data.items
              this.data_list = this.data_list.concat(list);
              this.loading1 = false
              this.is_next = res.data.data.hasMorePages
            })

        }


      }

    },
    drawText(test, x, y, width, context) {

      var chr = test.split("");
      var temp = "";
      var row = [];

      context.font = "30px Arial";
      context.fillStyle = "#fcf6dc";
      context.textAlign = "center"

      for (var a = 0; a < chr.length; a++) {
        if (context.measureText(temp).width < width) {
          ;
        }
        else {
          row.push(temp);
          temp = "";
        }
        temp += chr[a];
      }

      row.push(temp);
      if (row.length == 1) {
        y += 25
      }
      for (var b = 0; b < row.length; b++) {
        context.fillText(row[b], x, y + (b * 50));
      }
    },
    newCe(item) {
      let price = this.price_list.find((item1) => {
        return item1.id == item.level_id
      }).auth_fee
      weui.confirm(`为作品《${item.name}》生成证书需要花费${price}积分，是否生成？`, {
        title: "生成证书",
        buttons: [
          { label: this.$t('main.cancel'), type: "primary", onClick: () => { } },
          {
            label: "立即生成",
            type: "warn",
            className: "warn_dialog",
            onClick: () => {
              this.postNewCE(item);
            },
          },
        ],
      });

    },
    postNewCE(item) {
      this.axios.post('/collection/payTransApplyByScore', { goods_id: item.id }).then((res) => {
        if (res.data.code == 0) {
          setTimeout(() => {
            weui.alert('证书生成成功',
              {
                buttons: [{
                  label: this.$t('main.confIrm'), onClick: () => {
                    this.update()
                  }
                }]
              }
            )
          }, 500)
        } else {
          setTimeout(() => {
            weui.alert(this.$t('main.pointsgocz'), {
              buttons: [{

                label: this.$t('main.cancel'),
                onClick: () => {

                }
              }, {

                label: this.$t('main.determine'),
                type: 'primary',
                onClick: () => {
                  
                    this.$router.push('/chongzhi?status=1')
                  
                }
              }]
            })
          },500)
        }
        // WeixinJSBridge.invoke(
        // 	'getBrandWCPayRequest', {
        // 	appId: wxpay.appId,
        // 	timeStamp: wxpay.timestamp,
        // 	nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
        // 	package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        // 	signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        // 	paySign: wxpay.paySign,

        // }, (res) => {
        // 	if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 		weui.alert(this.$t('main.paysucc', { buttons: [{ label: this.$t('main.confIrm') }] }),
        // 			{
        // 				buttons: [{
        // 					label: this.$t('main.confIrm'), onClick: () => {
        // 						this.update()
        // 					}
        // 				}]
        // 			}
        // 		)
        // 	} else {
        // 		weui.alert(this.$t('main.payfail'), { buttons: [{ label: this.$t('main.confIrm') }] })
        // 		return
        // 	}
        // })
      })
    },
    goLm(item){
      this.$router.push({
        path:'/unit',
        query:{
          id:item.id,
          name:item.name,
          pictures:item.pictures[0],
          price:item.price,
          serial_number:item.serial_number,
          level:this.price_list.find((item1) => {
                  return item1.id == item.level_id
                }).name
        }
      })
    },
    getCollect(item){
      if(item.artist_source == 1){
        if(item.collector){
          return true
        }else if(item.status == 1 && item.my_edit.status == 0){
          return true
        }
        return false
      }else{
        if(item.collector && item.apply_collect_link.length == 0){
          return true
        }
        if(item.apply_collect_link.length){
          if(item.apply_collect_link[0].status == 0){
            return false
          }
        }
        return true
      }
    },
    isSignP(item){
      if(!this.is_sign_pass){
        return true
      }
      if(item.is_sign == 0 && item.artist_source == 1 ){
        return true
      }
      if(item.artist_source == 2){
        let list = item.link_artist_list
        let newItem = list.find((i)=>{return i.id == localStorage.getItem("art_id")}) || null
        if(newItem){
          if(newItem.is_sign == 0){
            return true
          }
        }
      }
      return false
    },
    verify(status){
      this.axios.post('goods/setLinkCollectRecord',{id:this.pop.id,status}).then((res)=>{
        if(res.data.code == 0){
          this.update()
          weui.alert(status == 1?'认证成功':'认证取消',()=>{
            this.link_zy = false
          })
        }else{
          weui.alert(res.data.msg)
        }
      })
    }


  },
};
</script>

<style scoped>
.out {
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  box-sizing: border-box;
  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}

.header {
  display: flex;
  justify-content: space-between;

  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-top: 0.01rem;
  margin-bottom: 0.15rem;
  padding: 0.05rem 0.15rem;
  align-items: center;
  background: #fff;
}

.cishu {
  padding-bottom: 0.15rem;
  color: #082958;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
}

.cishu img {
  display: block;
  width: 0.13rem;
  height: 0.13rem;
  margin-right: 0.1rem;
}

.Title {
  font-size: 0.18rem;
  position: relative;
}

.English {
  color: #1b6a21;
  opacity: 0.3;
  position: relative;
  top: 0.18rem;
  left: 0.15rem;
  text-align: left;
}

.Chinese {
  color: #1b6a21;

  left: 0;
  position: relative;
  text-align: left;
}

.Chinese .count {
  margin-left: 0.5rem;
}

.right-icon {
  width: 1rem;
  height: 0.39rem;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.zb-gl {
  font-size: 0.16rem;
  padding-bottom: 0.23rem;
}

.tap-box {
  display: flex;

  height: 0.36rem;
  line-height: 0.28rem;
  background: #ecedee;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.15rem;
  padding: 0.05rem 0rem;
  box-sizing: border-box;
}

.tap-box .tap-item {
  width: 1.15rem;
  text-align: center;
  color: #939393;
  font-size: 0.15rem;
  position: relative;
  white-space: nowrap;
}

.tap-box .line {
  width: 0.01rem;
  background: #d8d8d8;
}

.tap-box .tap-item .active {
  position: absolute;
  width: 1.15rem;
  height: 0.44rem;
  bottom: -0.06rem;
  background: url("../assets/img/tixing.png") no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tap-box .tap-item .active div {
  width: max-content;
  border-bottom: solid 2px #082958;
  color: #082958;
}

/* .tap-box .tap-item .active div::after{
		content: '';
		border-color: #3;
	} */
.zb-gl-box {
  font-size: 0.14rem;
}

.zb-gl-item {
  padding: 0.1rem 0.1rem;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.2rem;
  position: relative;
  border-radius: 0.05rem;
}

.zb_gl_item0 {
  background: #fff url("../assets/img/shz.png") no-repeat;
  background-size: 0.595rem 0.445rem;
  background-position: 2.67rem 1rem;
}

.zb_gl_item2 {
  background: #fff url("../assets/img/nopass.png") no-repeat;
  background-size: 0.595rem 0.445rem;
  background-position: 2.67rem 1.2rem;
}

.zb-gl-item .delete {
  font-size: 0.15rem;
  font-weight: 500;
  line-height: 0.24rem;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0.12rem;
  top: 0.12rem;

  height: 0.24rem;
  text-align: center;
  border-radius: 4px;
  color: #c7202e;
}

.is_hidden {
  position: absolute;
  right: 0.9rem;
  top: 0.12rem;
  color: #ff5152;
  font-size: 0.15rem;
  font-weight: 500;
}

.is_fail {
  position: absolute;
  width: 2.3rem;
  height: 0.48rem;
  left: 1rem;
  top: 1.65rem;
  color: #ff5152;
  font-size: 0.15rem;
  font-weight: 500;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-flex: 2;
  overflow: hidden;
}

.en-style .is_fail {
  left: 1.2rem;
  width: 2.2rem;
}

.en-style .pop-in1 .con .pass-input>div label {
  text-align: left;
}

.zb-gl-item .delete img {
  width: 0.16rem;
  height: 0.16rem;
  display: block;
  margin-right: 0.06rem;
}

.zb-gl-item .check-box {
  font-size: 0.15rem;
  font-weight: 500;
  line-height: 0.24rem;
  letter-spacing: 0px;
  color: #2a2a2a;

  left: 0.2rem;
  top: -0.12rem;
  /* width: 0.4rem; */
  /* height: 0.24rem; */
  display: flex;
  align-items: center;
}

.zb-gl-item .check-box:nth-child(2) {
  left: 0.8rem;
  top: 0.1rem;
  position: absolute;
}

.zb-gl-item .check-box .check-box {
  left: 0.1rem;
  top: 0;
  position: relative;
}

.zb-gl-item .check-box label {
  /* margin-left: 0.07rem;
				    padding-left: 0.25rem; */
  display: flex;
  align-items: center;
}

.zb-gl-item .check-box input {
  display: none;
}

.zb-gl-item .check-box label::before {
  display: inline-block;
  content: "";
  width: 0.19rem;
  height: 0.19rem;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #c1c1c1;
  margin-right: 0.07rem;
  /* left: 0;
			       top: 50%;
			       transform: translateY(-50%);
			       position: absolute; */
}

.zb-gl-item .check-box input:checked+label::before {
  display: inline-block;
  content: "";
  width: 0.19rem;
  height: 0.19rem;
  margin-right: 0.07rem;
  background: url(../assets/img/gou.png) no-repeat center;
  background-size: 0.11rem 0.08rem;
  box-shadow: none;
  background-color: #082958;
  /* position: absolute;
			       left: 0.05rem;
			       top: 50%;
			       transform: translateY(-50%); */
}

.zb-gl-item-top {
  display: flex;
  align-items: center;
  line-height: 0.24rem;
}

.zb-gl-item-top-left {
  width: 1.08rem;
  height: 1.08rem;
  border-radius: 0.06rem;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.zb-gl-item-top-left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.zb-gl-item-top-right {
  margin-left: 0.1rem;
  font-size: 0.14rem;
  white-space: nowrap;

  color: #939393;
  text-align: left;
}

.zb-gl-item-top-right>div {
  margin: 0.06rem 0;
}

.zb-title {
  max-width: 2rem;
  font-size: 0.18rem;
  font-weight: 500;
  color: #171717;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zb-gl-item-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 0.12rem;
  margin-top: 0.06rem;
  line-height: normal;
}

.zb-tips-item {
  display: flex;
  align-items: center;
}

.zb-tips-item img {
  width: 0.18rem;
}

.zb-rz-adopt {
  color: #9a2a25;
}

.zb-rz-adopt img {
  object-fit: contain;
  height: 0.2rem;
  margin-right: 0.1rem;
}

.zb-rz-adopt {
  display: flex;
  align-items: center;
}

.zb_renzhen {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.aiyy-logo-wrap {
  display: flex;
  justify-content: center;
  align-content: center;
}

.aiyy-logo-wrap span {
  white-space: nowrap;
}

.aiyy-logo {
  margin-left: 0.1rem;
  height: 0.15rem;
  margin-right: 0.05rem;
}

.zb-rz-rare-adopt {
  color: #f46246;
}

.zb-rz-rare-adopt div:nth-child(1) {}

.zb-rz-rare-adopt div:nth-child(1) img {
  width: 0.18rem;
  height: 0.18rem;
  display: block;
  object-fit: contain;
  margin-right: 0.07rem;
}

.zb-tips .zb-tips-item.zb-under-review {
  color: #07b9b9;
}

.zb-tips .zb-tips-item.zb-fail-agree {
  color: #f92100;
}

.zb-tips .zb-tips-item.zb-modify-review {
  color: #eb2f96;
}

.zb-tips-gn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0.1rem;
}

.en-style .zb-tips-gn-item {
  width: 1.06rem;
  background-size: 95% 90%;
}

.zb-tips-gn-item {
  width: 0.77rem;
  height: 0.4rem;
  border-radius: 0.08rem;
  background: url(../assets/img/my-zb-btn.png) no-repeat;
  background-position: center;
  background-size: 0.75rem 0.36rem;
  background-color: #d58158;
  color: #ffffff;
  word-break: keep-all;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.03rem;
  margin-top: 0.08rem;
}

.zb-tips-gn-item:nth-child(4n) {
  margin-right: 0;
}


.zb-tips-gn-item img {
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.05rem;
}

.zb-tips-gn-item:nth-child(2) {
  background-color: #798b78;
}

.en-style .zb-tips-gn-item:nth-child(3n) {
  margin-right: 0;
}

.en-style .zb-tips-gn-item:nth-child(4n) {
  margin-right: 0.03rem;
}

.zb-tips-gn-item.xg-btn {
  background-color: #2b264f;
}

.zb-tips-gn-item.fz-btn {
  background-color: #82afc2;
}

.zb-tips-gn-item:nth-child(2).prohibit {
  background: #6e6f70;
}

.zb-tips-gn-item:nth-child(3) {
  background-color: #97758d;
}

.zb-tips-gn-item:nth-child(4) {
  background-color: #444985;
}

.zb-tips-gn-item:nth-child(5) {
  background-color: #c99255;
}

.zb-tips-gn-item:nth-child(6) {
  background-color: #6e99af;
}

.zb-tips-gn-item:nth-child(7) {
  background-color: #9a5c45;
}

.zb-tips-gn-item:nth-child(8) {
  background-color: #42866f;
}

.zb-tips-gn-item .text {}

.agree-box {
  display: flex;
  align-items: center;
}

.agree-box span {
  font-size: 0.14rem;
  font-weight: 500;
  letter-spacing: 0px;
  color: #9e9e9e;
  margin-left: 0.1rem;
}

.submint-info {
  width: 100%;
  height: 0.45rem;
  line-height: 0.45rem;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  background: #3662ec;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
  margin-top: 0.29rem;
  margin-bottom: 0.17rem;
  /* letter-spacing: 0.3rem; */
}

.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: none; */
}

.pop-qrcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: url(../assets/img/longtap.png) no-repeat;
  background-size: 100% 100%; */
  width: 2.56rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-qrcode img {
  width: 2.27rem;
  /* height: 2.27rem;
  padding: 0.1rem;
  box-sizing: border-box;
  background-color: #fff; */
  object-fit: contain;
}

.pop-in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  width: 100%;
  padding: 0.25rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.pop-cy-rz .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pop-cy-rz .center input {
  width: 100%;
  height: 0.44rem;
  outline: none;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  border: none;
  padding-left: 0.1rem;
  margin-top: 0.26rem;
  box-sizing: border-box;
}

.pop-cy-rz .pop-tishi {
  color: white;
  margin-top: 0.1rem;
  font-size: 0.14rem;
}

.pop-cy-rz .pop-tishi>img {
  width: 0.14rem;
  vertical-align: middle;
  margin-right: 0.06rem;
}

.pop.pop-delete-goods {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* display: none; */
}

.pop.pop-delete-goods .pop-in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  font-size: 0.16rem;
  width: 100%;
  padding: 0;
}

.header-title {
  background-color: #3662ec;
  text-align: left;
  color: #ffffff;
  height: 0.45rem;
  line-height: 0.45rem;
  padding-left: 0.1rem;
}

.content {
  width: 100vw;
  padding: 0 0.15rem;
  box-sizing: border-box;
}

.content-bottom {
  min-height: 1.2rem;
  text-align: left;
  padding: 0.2rem;
}

.cancel {
  width: 45%;
  height: 0.45rem;
  margin: 0 auto;
  line-height: 0.45rem;
  text-align: center;
  color: #000;
  border-radius: 10px;
  background: #dee2ec;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
  margin-top: 0.23rem;
  margin-bottom: 0.23rem;
}

.sure {
  width: 45%;
  height: 0.45rem;
  margin: 0 auto;
  line-height: 0.45rem;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  background: #3662ec;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
  margin-top: 0.23rem;
  margin-bottom: 0.23rem;
}

.qrcode {
  width: 256px;
  height: 256px;
}

.password-box .agree-box-out {
  padding-left: 12%;
  margin-top: 0.075rem;
  margin-bottom: 0.075rem;
}

.password-box .pop-in .con .pass-input>div label {
  min-width: 12%;
}

.password-box .pop-in .con .err-tips {
  padding-left: 12%;
}

.password-box .pop-in .confirm-btn {
  margin: 0 auto;
}

.pop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.pop-in1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #edeae4;
  padding: 0.15rem;
  color: #171717;
  font-size: 0.12rem;
  width: 80%;
}

.close-pop-btn {
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  width: 0.23rem;
}

.pop-in1 .title {
  font-size: 0.18rem;
  display: flex;
  align-items: center;
}

.pop-in1 .title span {
  margin: 0 0.1rem;
}

.pop-in1 .title img {
  width: 0.18rem;
}

.pop-in1 .con .con-tips {
  margin: 0.2rem 0;
}

.pop-in1 .con .pass-input>div {
  display: flex;
  align-items: center;
  margin-bottom: 0.075rem;
}

.pop-in1 .con .pass-input>div label {
  min-width: 20%;
  text-align: right;
}

.pop-in1 .con .pass-input>div input {
  height: 0.33rem;
  line-height: 0.33rem;
  font-size: 0.14rem;
  border-radius: 0.05rem;
  border: none;
  outline: none;
  padding-left: 0.1rem;
  width: 70%;
  box-sizing: border-box;
}

.pop-in1 .con .err-tips {
  padding-left: 20%;
  display: flex;
  align-items: center;
  color: #ff180e;
}

.pop-in1 .con .err-tips img {
  width: 0.13rem;
  margin-right: 0.1rem;
}

.pop-in1 .confirm-btn {
  font-size: 0.14rem;
  height: 0.33rem;
  border-radius: 0.05rem;
  width: 100%;
  margin-left: 20%;
  width: 70%;
  text-align: center;
  color: #ffffff;
  background: #9a2a25;
  line-height: 0.33rem;
  margin-top: 0.22rem;
}

.agree-text {
  color: #a12f2a;
}

.gotocz {
  padding: 0.04rem 0.18rem;
  background: url(../assets/img/my-zb-btn.png) no-repeat #082958;
  background-size: 90% 0.24rem;
  background-position: center;
  font-size: 0.15rem;
  color: #fff;
  border-radius: 0.05rem;
  margin-left: auto;
  cursor: pointer;
}

.is_info {
  width: 0.16rem !important;
  height: 0.16rem !important;
  position: absolute;
  right: -0.06rem;
  margin: 0 !important;
  top: -0.06rem;
  background: #fff;
  border-radius: 50%;
}

.question {
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.05rem;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  margin-left: 0.05rem;
}
.aiyy-logo-wrap>div:nth-child(3){
  margin-left: 0.15rem;
}
.apply3D{
  display: flex;
  align-items: center;
  color: #1c129a;
}
.apply3D img{
  height: 0.15rem;
  object-fit: contain;
  margin-right: 0.05rem;
}
.link_ter{
  display: flex;
  align-items: center;
  color: #929292;
}
.link_ter img{
  height: 0.15rem;
  object-fit: contain;
  margin-right: 0.05rem;
}
.pop_zhuanyi {
	width: 3rem;

	background: url(../assets/img/zy_pop.png) no-repeat;
	background-size: 100% 100%;
	padding: 0.2rem 0.175rem;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 0.15rem;
	color: #fff;
}

.pop_titile {
	font-size: 0.15rem;
	color: #fff;
}

.pop_avatar {
	margin-top: 0.22rem;
	width: 0.84rem;
	height: 0.84rem;
	background: url(../assets/img/data_avatar_bg.png) no-repeat;
	background-size: 100% 100%;
	padding: 0.04rem;
	box-sizing: border-box;
}

.pop_avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.pop_wx_name {
	margin-top: 0.12rem;
	color: #feedcd;
	width: 100%;
	border-bottom: 1px dashed #e7bd74;
	text-align: center;
	padding-bottom: 0.135rem;
	box-sizing: border-box;
}

.pop_wx_name span {
	font-size: 0.12rem;
}
.pop_main {
	margin-top: 0.175rem;
	width: 100%;
	box-sizing: border-box;
	padding: 0 0.15rem;
	padding-bottom: 0.175rem;
	border-bottom: 1px dashed #e7bd74;
}

.pop_main>div>span {
	color: #feedcd;
}
.view_btn {
	position: relative;
	width: 2.28rem;
	height: 0.34rem;
	background: url(../assets/img/view-more.png) no-repeat;
	background-size: 100% 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.14rem;
	color: #333333;
	margin: 0.2rem auto;
}

.view_btn img {
	margin-top: 0.05rem;
	width: 0.2rem;
	height: 0.2rem;
}
.pop_close{
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
}
</style>
